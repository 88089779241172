import React from 'react';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {useStores} from '../../StoresContext';
import s from './ContinueShopping.scss';

export enum ContinueShoppingDataHook {
  Root = 'ContinueShopping.root',
  Title = 'ContinueShopping.title',
  Link = 'ContinueShopping.link',
}

export enum ContinueShoppingPosition {
  emptyState = 'ContinueShoppingPosition.empty-state',
  headline = 'ContinueShoppingPosition.headline',
}

const handleClick = (e: React.MouseEvent, callback: Function) => {
  e.preventDefault();
  callback();
};

export function ContinueShopping({position}: {position: ContinueShoppingPosition}) {
  const {get} = useSettings();
  const {continueShopping, continueShoppingHref} = useStores().navigationStore;
  const caption = get(settingsParams.CART_CONTINUE_SHOPPING_LINK) as string;

  return (
    <div className={s.root} data-hook={ContinueShoppingDataHook.Root} data-position={position}>
      <a
        className={position === ContinueShoppingPosition.emptyState ? s.emptyStateLink : s.link}
        aria-label={caption}
        data-hook={ContinueShoppingDataHook.Link}
        href={continueShoppingHref}
        onClick={(e) => handleClick(e, continueShopping)}>
        {caption}
      </a>
    </div>
  );
}
